// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill="#D8F3E9"
        stroke="#62CFA8"
        d="M6.92795557,4.91657132 C6.37252715,4.36114289 5.47199974,4.36114289 4.91657132,4.91657132 C4.36114289,5.47199974 4.36114289,6.37252715 4.91657132,6.92795557 L13.0720444,15.0834287 C13.6274729,15.6388571 14.5280003,15.6388571 15.0834287,15.0834287 C15.6388571,14.5280003 15.6388571,13.6274729 15.0834287,13.0720444 L6.92795557,4.91657132 Z"
      />
      <circle cx="7" cy="7" r="6.5" fill="#D8F3E9" stroke="#62CFA8" />
    </g>
  </svg>
)
