// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill="#D8F3E9"
        stroke="#62CFA8"
        d="M2 12.5C1.17157288 12.5.5 13.1715729.5 14 .5 14.8284271 1.17157288 15.5 2 15.5L14 15.5C14.8284271 15.5 15.5 14.8284271 15.5 14 15.5 13.1715729 14.8284271 12.5 14 12.5L2 12.5zM2 .5C1.17157288.5.5 1.17157288.5 2 .5 2.82842712 1.17157288 3.5 2 3.5L14 3.5C14.8284271 3.5 15.5 2.82842712 15.5 2 15.5 1.17157288 14.8284271.5 14 .5L2 .5zM2 6.5C1.17157288 6.5.5 7.17157288.5 8 .5 8.82842712 1.17157288 9.5 2 9.5L14 9.5C14.8284271 9.5 15.5 8.82842712 15.5 8 15.5 7.17157288 14.8284271 6.5 14 6.5L2 6.5z"
      />
    </g>
  </svg>
)
