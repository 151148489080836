// @flow

import * as React from 'react'
import styled from 'styled-components'
import VATContainerSvg from '../../../icons/VATContainerSvg'
import { FakeLink } from './../../../Typography/Link'

const Container = styled.div`
  width: 230px;
  height: 30px;
  position: absolute;
  bottom: -1px;
  left: 60px;
  z-index: 5;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 100ms ease-in-out;
`

const BackgroundWrapper = styled.div`
  position: absolute;
  line-height: 0;
`

const ContentContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  font-size: 14px;

  & ${FakeLink} {
    color: #adb1c2;
    text-decoration: none;

    &:hover {
      color: #adb1c2;
      text-decoration: underline;
    }
  }
`

export default ({
  visible = true,
  children,
}: {|
  visible?: boolean,
  children: React.Node,
|}) => {
  return (
    <Container visible={visible}>
      <BackgroundWrapper>
        <VATContainerSvg />
      </BackgroundWrapper>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  )
}
