// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'
import ArrowDownSvg from '../icons/ArrowDownSvg'

const Wrapper = styled.div`
  position: relative;
  margin: 0 2px;
`

const PlaceholderContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 1px 0 0 #ffffff;
  border: solid 1px ${Colors.greyRegular};
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  align-items: center;
  width: min-content;
  polyline {
    stroke: ${({ isActive }) =>
      isActive ? Colors.blackUltraBold : Colors.greyRegular};
  }
`

const Label = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: 11px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 #ffffff;
  line-height: 2.4;
  color: ${({ isActive, isFocus }) =>
    isActive || isFocus ? Colors.blackUltraBold : Colors.greyRegular};
  cursor: pointer;
  margin-top: 2px;
  margin-right: 4px;
`

const DropDownContainer = styled.div`
  border-radius: 4px;
  border: solid 1px transparent;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  padding: 0 8px;
  background-color: ${Colors.white};
  width: min-content;
  min-width: 144px;
  position: absolute;
  z-index: 1;
  top: 0;
`

const ChoicesContainer = styled.div`
  padding: 8px 0;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.greyLight};
`

const Choice = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 5px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const ChoiceRadio = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: ${({ isActive }) =>
    isActive ? `solid 1px ${Colors.green}` : `solid 1px ${Colors.greyRegular}`};
  background-color: ${({ isActive }) =>
    isActive ? Colors.green : 'transparent'};
  transition: all 0.25s ease-in-out;
  ${Choice}:hover & {
    border: solid 1px ${Colors.green};
  }
`

const ChoiceLabel = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 1.43;
  font-size: 14px;
  text-shadow: 0 1px 0 #ffffff;
  margin-left: 4px;
  color: ${({ isActive }) =>
    isActive ? Colors.blackUltraBold : Colors.greyRegular};
  transition: color 0.25s ease-in-out;
  ${Choice}:hover & {
    color: ${Colors.blackUltraBold};
  }
`

type Value = string | { from: number, to: number } | number

type Props = {
  id: string,
  label: string,
  comparator: (a: any, b: any) => boolean,
  choices: Array<{
    label: string,
    value: Value,
  }>,
  onChoiceClick: (id: string, value: Value) => void,
  values: Array<any>,
}

type State = {
  isOpen: boolean,
}

export default class SelectRadio extends React.Component<Props, State> {
  _wrapperRef: any

  constructor(props: Props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: SyntheticEvent<HTMLElement>) => {
    if (this._wrapperRef && !this._wrapperRef.contains(event.target)) {
      this.toggleDropdown()
    }
  }

  toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onChoiceClick = (value: Value) => {
    const { id, onChoiceClick } = this.props
    onChoiceClick(id, value)
  }

  render() {
    const { label, choices, comparator, values } = this.props
    const { isOpen } = this.state

    return (
      <Wrapper>
        <PlaceholderContainer
          isActive={values.length > 0}
          onClick={() => this.toggleDropdown()}
        >
          <Label isActive={values.length > 0}>{label}</Label>
          <ArrowDownSvg />
        </PlaceholderContainer>

        {isOpen ? (
          <DropDownContainer>
            <Label isFocus>{label}</Label>
            <Separator />
            <ChoicesContainer ref={node => (this._wrapperRef = node)}>
              {choices.map(oneChoice => {
                const isActive =
                  values.find(oneValue =>
                    comparator(oneChoice.value, oneValue),
                  ) !== undefined

                return (
                  <Choice
                    onClick={() => this.onChoiceClick(oneChoice.value)}
                    key={oneChoice.label}
                  >
                    <ChoiceRadio isActive={isActive} />
                    <ChoiceLabel isActive={isActive}>
                      {oneChoice.label}
                    </ChoiceLabel>
                  </Choice>
                )
              })}
            </ChoicesContainer>
          </DropDownContainer>
        ) : null}
      </Wrapper>
    )
  }
}
