// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="10"
    height="6"
    viewBox="0 0 10 6"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <polyline stroke="#ADB1C2" strokeWidth="1.5" points="1 1 4.966 5 9 1" />
    </g>
  </svg>
)
