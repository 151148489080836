// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16 16"
    width="16"
    height="16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <circle cx="8" cy="8" r="7.5" fill="#D8F3E9" stroke="#62CFA8" />
      <path
        fill="#D8F3E9"
        stroke="#62CFA8"
        d="M6.96657509,6.96657509 L5.41643773,10.5835623 L9.03342491,9.03342491 L10.5835623,5.41643773 L6.96657509,6.96657509 Z"
      />
    </g>
  </svg>
)
