// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill="#D8F3E9"
        stroke="#62CFA8"
        d="M2.5638852,0.5 C1.82655093,0.5 1.54701959,0.553979991 1.25895373,0.708039303 C1.02016204,0.835746496 0.835746496,1.02016204 0.708039303,1.25895373 C0.553979991,1.54701959 0.5,1.82655093 0.5,2.5638852 L0.5,13.4361148 C0.5,14.1734491 0.553979991,14.4529804 0.708039303,14.7410463 C0.835746496,14.979838 1.02016204,15.1642535 1.25895373,15.2919607 C1.54701959,15.44602 1.82655093,15.5 2.5638852,15.5 L13.4361148,15.5 C14.1734491,15.5 14.4529804,15.44602 14.7410463,15.2919607 C14.979838,15.1642535 15.1642535,14.979838 15.2919607,14.7410463 C15.44602,14.4529804 15.5,14.1734491 15.5,13.4361148 L15.5,2.5638852 C15.5,1.82655093 15.44602,1.54701959 15.2919607,1.25895373 C15.1642535,1.02016204 14.979838,0.835746496 14.7410463,0.708039303 C14.4529804,0.553979991 14.1734491,0.5 13.4361148,0.5 L2.5638852,0.5 Z"
      />
    </g>
  </svg>
)
