// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="230"
    height="30"
    viewBox="0 0 230 30"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill="#f9fafc"
        d="M15.7660738,-7.10542736e-15 L214.233926,-7.10542736e-15 C217.67737,-1.66197618e-14 220.73448,2.20344076 221.823393,5.47017787 L230,30 L0,30 L8.17660738,5.47017787 C9.26551975,2.20344076 12.3226305,-1.14380657e-15 15.7660738,-7.10542736e-15 Z"
      />
    </g>
  </svg>
)
