// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'

const CardContainer = styled.div`
  width: 310px;
  height: 310px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: ${Colors.white};
  margin: calc(25px / 2);
  transition: all 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
    margin-top: calc((25px / 2) - 5px);
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  }
`

const Title = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 22px;
  font-weight: ${RobotoMedium.fontWeight};

  text-align: center;
  color: ${Colors.blackUltraBold};
`
const SubTitle = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  text-align: center;
  color: #adb1c2;
`

export const ProgramCardSummary = ({
  label,
  location,
  totalNumberOfLot,
}: {
  label: string,
  location: string,
  totalNumberOfLot: number,
}) => {
  return (
    <React.Fragment>
      <Title>{label}</Title>
      <SubTitle>
        {location} |{' '}
        {totalNumberOfLot > 1
          ? `${totalNumberOfLot} logements`
          : `${totalNumberOfLot} logement`}
      </SubTitle>
    </React.Fragment>
  )
}

export default ({ children }: { children: React.Node }) => (
  <CardContainer>{children}</CardContainer>
)
