// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../../../Colors'
import { RobotoMedium } from '../../../Typography/Fonts'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: ${({ isCardOver }) => (isCardOver ? 'absolute' : 'initial')};
  opacity: ${({ isOver, isCardOver, canDrop }) =>
    isCardOver ? (canDrop ? (isOver ? 1 : 0.7) : 0) : 1};
  pointer-events: ${({ isCardOver }) => (isCardOver ? 'none' : 'initial')};
  transition: opacity 0.2s ease-in-out;
  z-index: ${({ isCardOver }) => (isCardOver ? 1 : 0)};
`

const ColumnWrapper = styled.div`
  display: flex;
  position: relative;
  width: ${({ collapsed }) => (collapsed ? '30px' : '330px')};
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 1px 0 0 #ffffff, inset 0 0 8px 0 rgba(0, 0, 0, 0.05);
  background-color: ${({ isCardOver }) =>
    isCardOver ? Colors.greenLight : 'rgba(0, 0, 0, 0.05)'};
  margin: 0 10px;
  padding: 10px 0;
  border: ${({ isCardOver }) =>
    isCardOver ? `dashed 2px ${Colors.green}` : 'none'};

  transition: width 250ms ease-in-out;
  overflow: ${({ collapsed }) => (collapsed ? 'hidden' : 'auto')};
  cursor: ${({ collapsed }) => (collapsed ? 'pointer' : 'default')};
`

const ColumnCard = styled.div`
  overflow: auto;
  height: 100%;

  pointer-events: ${({ collapsed }) => (collapsed ? 'none' : 'auto')};
  overflow: ${({ collapsed }) => (collapsed ? 'hidden' : 'auto')};
  opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
  transition: opacity 250ms ease-in-out;
`

const ColumnLabel = styled.div`
  text-shadow: 0 1px 0 #ffffff;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 1.71;
  color: ${({ isCardOver }) =>
    isCardOver ? Colors.green : Colors.greyRegular};
  text-align: center;

  opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
  width: ${({ collapsed }) => (collapsed ? '30px' : '330px')};
  transition: all 250ms ease-in-out;
`

const VerticalLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  text-shadow: 0 1px 0 #ffffff;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 1.71;
  color: ${({ isCardOver }) =>
    isCardOver ? Colors.green : Colors.greyRegular};

  opacity: ${({ collapsed }) => (collapsed ? 1 : 0)};
  transition: opacity 150ms ease-in-out;
`
const RotatedLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  text-align: center;
  transform-origin: center left;
  transform: rotate(-90deg);
`

export const ColumnCardDroppableArea = ({
  title,
  canDrop,
  isOver,
  collapsed = false,
}: {
  title: string,
  canDrop: boolean,
  isOver: boolean,
  collapsed?: boolean,
}) => {
  return (
    <Container
      isCardOver
      canDrop={canDrop}
      isOver={isOver}
      collapsed={collapsed}
    >
      <ColumnLabel isCardOver collapsed={collapsed}>
        {title}
      </ColumnLabel>

      <ColumnWrapper isCardOver collapsed={collapsed}>
        <ColumnCard />
      </ColumnWrapper>
    </Container>
  )
}

export default ({
  title,
  children,
  collapsed = false,
  onClick,
  collapsable,
}: {|
  title: string,
  children: React.Node,
  collapsed?: boolean,
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void,
  collapsable: boolean,
|}) => {
  return (
    <Container collapsed={collapsed}>
      <ColumnLabel collapsed={collapsed}>{title}</ColumnLabel>

      <ColumnWrapper collapsed={collapsed} onClick={e => onClick && onClick(e)}>
        {collapsable ? (
          <VerticalLabel collapsed={collapsed}>
            <RotatedLabel>{title}</RotatedLabel>
          </VerticalLabel>
        ) : null}
        <ColumnCard collapsed={collapsed}>{children}</ColumnCard>
      </ColumnWrapper>
    </Container>
  )
}
