import { RobotoMedium } from './../../Typography/Fonts'
import { Colors } from '../../Colors'

export default {
  container: {
    position: 'relative',
    height: '100%',
  },

  input: {
    width: '100%',
    height: '60px',
    padding: '20px 16px 20px 42px',
    fontFamily: RobotoMedium.fontFamily,
    fontSize: '17px',
    fontWeight: RobotoMedium.fontWeight,
    lineHeight: '20px',
    border: '1px solid',
    borderRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    borderColor: '#dddddd',
    color: Colors.blackUltraBold,
    transition: 'all 0.25s ease-in-out',
  },

  inputFocused: {
    outline: 'none',
    borderColor: Colors.green,
  },

  inputOpen: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderColor: Colors.green,
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.08)',
  },

  suggestionsContainer: {
    opacity: '0',
    transition: 'opacity 0.25s ease-in-out',
  },

  suggestionsContainerOpen: {
    opacity: '1',
    position: 'absolute',
    top: '59px',
    width: '100%',
    border: `1px solid ${Colors.green}`,
    maxHeight: '40vh',
    overflow: 'auto',
    borderTop: 'none',
    backgroundColor: '#fff',
    fontWeight: '400',
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.08)',
    fontSize: '16px',
    marginBottom: '40px',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    zIndex: '2',
  },

  suggestionsList: {
    margin: '0',
    padding: '0',
    listStyleType: 'none',
  },

  suggestion: {
    cursor: 'pointer',
    padding: '12px 16px',
    borderTop: `1px solid ${Colors.greyLight}`,
  },

  suggestionHighlighted: {
    backgroundColor: '#fcfcfc',
  },
}
