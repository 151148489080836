// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#DDD"
      fillRule="evenodd"
      d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-14.121L8.818 6.697a1.5 1.5 0 1 0-2.121 2.121L9.879 12l-3.182 3.182a1.5 1.5 0 0 0 2.121 2.121L12 14.121l3.182 3.182a1.5 1.5 0 0 0 2.121-2.121L14.121 12l3.182-3.182a1.5 1.5 0 0 0-2.121-2.121L12 9.879z"
    />
  </svg>
)
