// @flow
import * as React from 'react'
import nanoid from 'nanoid'
import styled, { createGlobalStyle } from 'styled-components'
import Autosuggest from 'react-autosuggest'
import { Colors } from '../../Colors'
import theme from './AutocompleteTheme'
import SearchSvg from '../../icons/SearchSvg'

const Wrapper = styled.div`
  position: relative;
`

const SearchIcon = styled.div`
  position: absolute;
  left: 16px;
  top: 22px;
  z-index: 1;
`

type Props = {
  valueId: string,
  values: Array<Object>,
  placeholder: string,
  autoFocus?: boolean,
  searchableFields: string[],
  renderSuggestion: (suggestion: Object) => React.Node,
  getSuggestionValue: (suggestion: Object) => string,
  onSuggestionSelected: (suggestion: Object) => void,
}

type State = {
  value: string,
  suggestions: Array<Object>,
  autocompleteId: string,
  GlobalStyle: React.StatelessFunctionalComponent<{}>,
}

class AutocompleteField extends React.Component<Props, State> {
  constructor() {
    super()
    const autocompleteId = `autocomplete-${nanoid()}`
    this.state = {
      value: '',
      suggestions: [],
      autocompleteId: autocompleteId,
      GlobalStyle: createGlobalStyle`
      #${autocompleteId} input::placeholder {
        color: ${Colors.greyRegular};
      }
      #${autocompleteId} input[type="search"] {
        appearance: textField;
      }
    `,
    }
  }

  getSuggestions = (value: string): Array<Object> => {
    const { values, searchableFields, valueId } = this.props
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const splitedValue = inputValue.split(' ')
    return inputLength === 0
      ? []
      : values.reduce((acc, elem) => {
          searchableFields.forEach(field => {
            splitedValue.forEach(split => {
              if (
                elem[field] &&
                elem[field].toLowerCase().includes(split) &&
                !acc.some(item => item[valueId] === elem[valueId])
              ) {
                acc.push(elem)
              }
            })
          })
          return acc
        }, [])
  }

  onSuggestionSelected = (
    e: SyntheticEvent<HTMLFormElement>,
    { suggestion }: { suggestion: Object },
  ) => {
    this.props.onSuggestionSelected(suggestion)
  }

  getSuggestionValue = (suggestion: Object): string => {
    return this.props.getSuggestionValue(suggestion)
  }

  renderSuggestion = (suggestion: Object) =>
    this.props.renderSuggestion(suggestion)

  onChange = (
    e: SyntheticEvent<HTMLFormElement>,
    { newValue }: { newValue: string },
  ) => {
    this.setState({
      value: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  render() {
    const { value, suggestions, autocompleteId, GlobalStyle } = this.state
    const { placeholder, autoFocus } = this.props
    const inputProps = {
      placeholder: placeholder,
      value,
      onChange: this.onChange,
      autoFocus: autoFocus,
      type: 'search',
    }
    return (
      <Wrapper id={autocompleteId}>
        <GlobalStyle />
        <SearchIcon>
          <SearchSvg />
        </SearchIcon>
        <Autosuggest
          onSuggestionSelected={this.onSuggestionSelected}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          theme={theme}
        />
      </Wrapper>
    )
  }
}

export default AutocompleteField
